<template>
    <div id="sc-edit-product">
        <b-button class="sc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <h1>Edit Product</h1>
        <hr />
        <b-form @submit="onSubmitEditProduct">
            <b-form-group id="sc-name-container" label="Product Name">
                <div>{{ form.name }}</div>
            </b-form-group>

            <b-form-group id="sc-type-container" label="Product Type">
                <div v-if="form.itemType">{{ form.itemType.name }}</div>
            </b-form-group>

            <b-form-group id="sc-type-container" label="SKU">
                <div>{{ form.sku }}</div>
            </b-form-group>

            <b-form-group id="sc-price-container" label="Price">
                <b-form-input v-model="form.price" type="text" placeholder="Enter price"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-quickbooksid-container" label="Quickbooks Id">
                <b-form-input v-model="form.quickbooksId" type="text" placeholder="Enter Quickbooks id"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-description-container" label="Description">
                <b-form-input v-model="form.description" placeholder="Enter description"></b-form-input>
            </b-form-group>

            <b-button class="sc-button-primary" type="submit" variant="primary">Save</b-button>
        </b-form>
    </div>
</template>

<script>
import APICalls from "./APICalls";

export default {
    name: "EditProduct",
    data() {
        return {
            form: {},
            productTypes: [],
        };
    },
    methods: {
        onSubmitEditProduct(event) {
            event.preventDefault();
            this.editProduct(this.form);
        },
        async editProduct(params) {
            let id = this.$router.currentRoute.params.id;
            let response = await APICalls.editProduct(id, params);
            console.log(response);
            this.$toasted.show("Edit product success!", { position: "bottom-center", className: "sc-toast-success", duration: 6000 });
        },
        async getProduct() {
            let id = this.$router.currentRoute.params.id;
            let product = await APICalls.getProduct(id);
            this.form = product;
        },
        async getProductTypes() {
            let productTypes = await APICalls.getProductTypes();
            this.productTypes = productTypes;
        },
    },
    mounted() {
        this.getProduct();
        this.getProductTypes();
    },
};
</script>

<style lang="scss">
#sc-edit-product {
    form {
        margin-top: 20px;

        .col-form-label {
            font-size: 20px;
        }

        #sc-name-container,
        #sc-sku-container,
        #sc-type-container,
        #sc-price-container,
        #sc-quickbooksid-container {
            display: inline-block;
            width: 20%;
        }

        #sc-sku-container,
        #sc-type-container,
        #sc-price-container,
        #sc-quickbooksid-container {
            padding-left: 25px;
        }

        #sc-type-container {
            #sc-dropdown-type {
                display: inline-flex;
                width: 90%;
                margin: 0px !important;
            }

            #sc-dropdown-value {
                width: 10%;
                display: inline-flex;
                justify-content: flex-end;
            }
        }

        #sc-description-container {
            display: inline-block;
            width: 50%;
        }

        .sc-button-primary {
            display: block;
            width: 180px;
            margin-top: 10px;
        }
    }
}
</style>
